import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./kitchens-interactive-section.scss"
import { graphql, StaticQuery } from "gatsby"
import ImageZoom from "../zoomable-gatsby-image"
import Fade from 'react-reveal/Fade'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

const KitchensSection = () => (
  <StaticQuery query={graphql`
  query {
    kitchenone: file(relativePath: { eq: "kitchenone.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kitchentwo: file(relativePath: { eq: "kitchentwo.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kitchenthree: file(relativePath: { eq: "kitchenthree.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kitchenfour: file(relativePath: { eq: "kitchenfour.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`}
    render={data => {
      const kitchenOneImage = data.kitchenone.childImageSharp.fluid
      const kitchenTwoImage = data.kitchentwo.childImageSharp.fluid
      const kitchenThreeImage = data.kitchenthree.childImageSharp.fluid
      const kitchenFourImage = data.kitchenfour.childImageSharp.fluid

      const coffeeGlossTooltip = (props) => (
        <Tooltip {...props}>
          Кофемашина Smeg CMS4104S
        </Tooltip>
      );
      const ovenGlossTooltip = (props) => (
        <Tooltip {...props}>
          Духовой шкаф Smeg SF4102VCS
        </Tooltip>
      );
      const ovenArtdecoTooltip = (props) => (
        <Tooltip {...props}>
          Духовой шкаф Miele H2860B BRWS бриллиантовый белый
        </Tooltip>
      );
      const tabletopGlossTooltip = (props) => (
        <Tooltip {...props}>
          Столешница Iron Grey Satin Neolith
        </Tooltip>
      );
      const chandelierArtDecoTooltip = (props) => (
        <Tooltip {...props}>
          Люстра Garda Décor "Лепестки" K2KG02P-6G
        </Tooltip>
      );
      const facadeGlossKitchenTooltip = (props) => (
        <Tooltip {...props}>
          Фасады стеклоламинат глянец Rehau Sabbia 1687 L
        </Tooltip>
      );
      const chandelierGlossKitchenTooltip = (props) => (
        <Tooltip {...props}>
          Люстры Garda Décor 91GH-3503
        </Tooltip>
      );
      const knobsClassicTooltip = (props) => (
        <Tooltip {...props}>
          Ручки MAKMART 160мм, отделка медь
        </Tooltip>
      );
      const knobsArtdecoTooltip = (props) => (
        <Tooltip {...props}>
          Ручки Аполло-2 сатин
        </Tooltip>
      );
      const knobsGlossTooltip = (props) => (
        <Tooltip {...props}>
          Ручки вертикальные Gola в цвет фасадов
        </Tooltip>
      );
      const knobsTooltip = (props) => (
        <Tooltip {...props}>
          Ручки AMIX Viefe Graf2
        </Tooltip>
      );
      const kitchenClassicSinkTooltip = (props) => (
        <Tooltip {...props}>
          Мойка из кварцевого агломерата в цвет столешницы
        </Tooltip>
      );
      const kitchenArtdecoSinkTooltip = (props) => (
        <Tooltip {...props}>
          Мойка Alveus Quadrix Monarch 60 золото
        </Tooltip>
      );
      const kitchenGlossSinkTooltip = (props) => (
        <Tooltip {...props}>
          Мойка OMOIKIRI TAKI 54-U/IF-GM (4973107)
        </Tooltip>
      );
      const kitchenSinkTooltip = (props) => (
        <Tooltip {...props}>
          Мойка накладная Blanco Andano 400-IF/A
        </Tooltip>
      );
      const islandTabletopTooltip = (props) => (
        <Tooltip {...props}>
          Столешница на остров - реконструированный шпон Arbor Nova 119 PT орех волнистый с покрытием HM (износостойкое)
        </Tooltip>
      );
      const facadeIslandTooltip = (props) => (
        <Tooltip {...props}>
          Фасады пластик Сити Пласт антипальчиковый 3105 «бежевый матовый»
        </Tooltip>
      );
      const blancoTooltip = (props) => (
        <Tooltip {...props}>
          Смеситель Blanco Catris-S хром с выдвижной лейкой
        </Tooltip>
      );
      const faucetArtdecoTooltip = (props) => (
        <Tooltip {...props}>
          Смеситель Alveus Slim Monarch 1095004
        </Tooltip>
      );
      const omoikiriGlossTooltip = (props) => (
        <Tooltip {...props}>
          Смеситель OMOIKIRI YATOMI-BN-BL(4994247), нержавеющая сталь/черный шланг
        </Tooltip>
      );
      const omoikiriTooltip = (props) => (
        <Tooltip {...props}>
          Смеситель Omoikiri Amagasaki-AC
        </Tooltip>
      );
      const facadeTooltip = (props) => (
        <Tooltip {...props}>
          Фасады реконструированный шпон Arbor Nova 119 PT орех волнистый покрытие Mons, вертикальное расположение текстуры
        </Tooltip>
      );
      const facadeClassicTooltip = (props) => (
        <Tooltip {...props}>
          Фасады КАНЗАС цвет Tikkurila S432 матовый
        </Tooltip>
      );
      const neffOvenTooltip = (props) => (
        <Tooltip {...props}>
          Духовой шкаф NEFF B48CT64H0
        </Tooltip>
      );
      const smegOvenTooltip = (props) => (
        <Tooltip {...props}>
          Духовой шкаф Smeg SFP6604WTPNR
        </Tooltip>
      );
      const smegWineCabinetTooltip = (props) => (
        <Tooltip {...props}>
          Винный шкаф Smeg CVI618LWNR2
        </Tooltip>
      );
      const coffeeArtdecoTooltip = (props) => (
        <Tooltip {...props}>
          Кофемашина Miele CVA7845 BRWS бриллиантовый белый
        </Tooltip>
      );
      const smegSommelierBoxTooltip = (props) => (
        <Tooltip {...props}>
          Ящик сомелье Smeg CPS615NR
        </Tooltip>
      );
      const mieleArtdecoTooltip = (props) => (
        <Tooltip {...props}>
          Вакууматор Miele EVS7010 BRWS бриллиантовый белый
        </Tooltip>
      );
      const smegCookingStoveTooltip = (props) => (
        <Tooltip {...props}>
          Электрическая варочная панель Smeg SI2M7643DW
        </Tooltip>
      );
      const mieleArtdecoStoveTooltip = (props) => (
        <Tooltip {...props}>
          Варочная поверхность Miele KM6349-1
        </Tooltip>
      );
      const chandelierTooltip = (props) => (
        <Tooltip {...props}>
          Люстра Garda Décor из прозрачного стекла (хром)
        </Tooltip>
      );
      const chandelierClassicTooltip = (props) => (
        <Tooltip {...props}>
          Люстры стеклянные Garda Décor 20MD3399-4NI
        </Tooltip>
      );
      return (
        <>
          <Container className="mx-auto">
            <Fade duration={1000} delay={150}>
              <Tabs defaultActiveKey="veneer" id="kitechens-tabs" variant="pills">
                <Tab eventKey="veneer" title="Кухня в шпоне">
                  <Row>
                    <Col md={12} className="kitchen-one py-3">
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={knobsTooltip}
                      >
                        <button id="door-knobs" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={kitchenSinkTooltip}
                      >
                        <button id="kitchen-sink" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={islandTabletopTooltip}
                      >
                        <button id="island-tabletop" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={facadeIslandTooltip}
                      >
                        <button id="facade-island" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={blancoTooltip}
                      >
                        <button id="blanco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={facadeTooltip}
                      >
                        <button id="facade" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={neffOvenTooltip}
                      >
                        <button id="neff" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={chandelierTooltip}
                      >
                        <button id="chandelier" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <ImageZoom fluid={kitchenOneImage} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="classic" title="Классическая кухня">
                  <Row>
                    <Col md={12} className="kitchen-two py-3">
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={knobsClassicTooltip}
                      >
                        <button id="door-knobs-classic" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={kitchenClassicSinkTooltip}
                      >
                        <button id="kitchen-classic-sink" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={facadeClassicTooltip}
                      >
                        <button id="facade-classic" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={omoikiriTooltip}
                      >
                        <button id="omoikiri" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={chandelierClassicTooltip}
                      >
                        <button id="chandelier-classic" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={smegOvenTooltip}
                      >
                        <button id="smeg-classic" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={smegWineCabinetTooltip}
                      >
                        <button id="smeg-classic-wine-cab" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={smegSommelierBoxTooltip}
                      >
                        <button id="smeg-classic-sommelier-box" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={smegCookingStoveTooltip}
                      >
                        <button id="smeg-classic-cooking-stove" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <ImageZoom fluid={kitchenTwoImage} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="artdeco" title="Кухня Арт-деко">
                  <Row>
                    <Col md={12} className="kitchen-three py-3">
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={chandelierArtDecoTooltip}
                      >
                        <button id="chandelier-artdeco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={kitchenArtdecoSinkTooltip}
                      >
                        <button id="kitchen-artdeco-sink" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={faucetArtdecoTooltip}
                      >
                        <button id="faucet-artdeco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={knobsArtdecoTooltip}
                      >
                        <button id="door-knobs-artdeco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={ovenArtdecoTooltip}
                      >
                        <button id="oven-artdeco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={coffeeArtdecoTooltip}
                      >
                        <button id="miele-coffee-artdeco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={mieleArtdecoTooltip}
                      >
                        <button id="miele-artdeco" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={mieleArtdecoStoveTooltip}
                      >
                        <button id="miele-artdeco-stove" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <ImageZoom fluid={kitchenThreeImage} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="gloss" title="Кухня в глянце">
                  <Row>
                    <Col md={12} className="kitchen-four py-3">
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={chandelierGlossKitchenTooltip}
                      >
                        <button id="chandelier-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={facadeGlossKitchenTooltip}
                      >
                        <button id="facade-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={tabletopGlossTooltip}
                      >
                        <button id="tabletop-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={ovenGlossTooltip}
                      >
                        <button id="oven-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={coffeeGlossTooltip}
                      >
                        <button id="coffee-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={knobsGlossTooltip}
                      >
                        <button id="door-knobs-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={kitchenGlossSinkTooltip}
                      >
                        <button id="kitchen-gloss-sink" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 250 }}
                        overlay={omoikiriGlossTooltip}
                      >
                        <button id="omoikiri-gloss" className='btn btn-circle btn-light font-weight-bold text-dark'>&#43;</button>
                      </OverlayTrigger>
                      <ImageZoom fluid={kitchenFourImage} />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Fade>
          </Container>
        </>
      )
    }}
  />
)

export default KitchensSection

